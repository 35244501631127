import React from "react";
import { SvgIcon } from "@mui/material";

function Category10({ color, props, isDarkMode }) {
  return (
    <SvgIcon viewBox="0 0 512 512">
      {/* Maintain the viewBox of your SVG */}
      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512" {...props}>
        <path
          d="M442.75 30C457.78 30 470 42.22 470 57.25v385.5c0 15.03-12.22 27.25-27.25 27.25H57.25C42.22 470 30 457.78 30 442.75V57.25C30 42.22 42.22 30 57.25 30h385.5m0-30H57.25C25.63 0 0 25.63 0 57.25v385.5C0 474.37 25.63 500 57.25 500h385.5c31.62 0 57.25-25.63 57.25-57.25V57.25C500 25.63 474.37 0 442.75 0Z"
          fill={color}
        />
        <path
          d="M240.98 337.85c-4.86 0-9.06-1.57-12.59-4.71-3.53-3.14-5.3-7.53-5.3-13.18 0-4.79 1.69-8.9 5.06-12.36 3.37-3.45 7.53-5.18 12.47-5.18s9.16 1.71 12.65 5.12 5.24 7.55 5.24 12.41c0 5.57-1.75 9.94-5.24 13.12-3.49 3.18-7.59 4.77-12.3 4.77ZM406.58 249.45c0 12.59-.77 23.42-2.3 32.48-1.54 9.06-4.38 17.27-8.52 24.65-5.22 9.06-11.98 16.05-20.27 20.96s-17.51 7.37-27.64 7.37c-11.67 0-22.13-3.3-31.38-9.9-9.25-6.6-16.22-15.74-20.9-27.41-2.38-6.37-4.13-13.42-5.24-21.13s-1.67-16.07-1.67-25.05c0-11.59.61-22.04 1.84-31.33s3.15-17.27 5.76-23.95c4.53-11.06 11.11-19.48 19.75-25.28 8.64-5.8 18.94-8.7 30.92-8.7 7.91 0 15.09 1.29 21.54 3.86s12.11 6.33 16.99 11.29c4.88 4.95 9 11.08 12.38 18.37 5.84 12.67 8.75 30.6 8.75 53.78Zm-31.56-2.3c0-14.05-.84-25.55-2.53-34.49-1.69-8.94-4.53-15.7-8.52-20.27s-9.52-6.85-16.58-6.85c-10.13 0-17.18 5.11-21.13 15.32s-5.93 25.95-5.93 47.22c0 14.43.84 26.26 2.53 35.47 1.69 9.21 4.53 16.18 8.52 20.9 3.99 4.72 9.41 7.08 16.24 7.08s12.59-2.46 16.58-7.37 6.79-11.94 8.41-21.08 2.42-21.11 2.42-35.93ZM144.46 316.49V213.06c-19.25 14.78-32.2 22.16-38.87 22.16-3.18 0-6.01-1.27-8.47-3.81-2.46-2.54-3.69-5.47-3.69-8.81 0-3.86 1.21-6.71 3.64-8.52s6.71-4.17 12.84-7.05c9.17-4.32 16.5-8.87 21.99-13.64a93.409 93.409 0 0 0 14.61-16.03c4.24-5.91 7.01-9.55 8.3-10.91s3.71-2.05 7.27-2.05c4.02 0 7.24 1.55 9.66 4.66 2.42 3.11 3.64 7.39 3.64 12.84v130.14c0 15.23-5.19 22.85-15.57 22.85-4.62 0-8.34-1.55-11.14-4.66-2.8-3.11-4.21-7.69-4.21-13.75Z"
          fill={isDarkMode ? "#eeeeee" : "#000000"}
        />
      </svg>
    </SvgIcon>
  );
}

export default Category10;
