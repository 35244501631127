import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import { drawerWidth } from "../services/data";
import { useMediaQuery } from "react-responsive";
import Switch from "@mui/material/Switch"; // Import the Switch component
import DarkModeContext from "../services/DarkModeContext";
import Brightness3TwoToneIcon from "@mui/icons-material/Brightness3TwoTone";
import LightModeTwoToneIcon from "@mui/icons-material/LightModeTwoTone";
import { purple } from "@mui/material/colors";

//import useDeviceDetection from "../hooks/useDeviceDetection";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HeaderComponent = ({ isOpen, handleDrawerOpen, theme }) => {
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  //const device = useDeviceDetection();
  //const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  //const isBetween = useMediaQuery({ query: "(min-width: 600px) and (max-width: 1224px) and (orientation: portrait)" });

  const [shouldHideBar, setShouldHideBar] = useState(false);
  let lastScrollY = 0;

  // Scroll event handler
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      // Adjust threshold (100) as needed
      setShouldHideBar(true);
    } else {
      setShouldHideBar(false);
    }
    lastScrollY = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        open={isOpen}
        theme={theme}
        className={shouldHideBar && isTabletOrMobile && !isPortrait ? "hide-bar" : ""}
        //sx={{ backgroundColor: "#1976d2" }}
      >
        <Toolbar
          sx={{ justifyContent: isOpen && isTabletOrMobile ? "space-around" : "space-between", p: isOpen ? 0 : "" }}
        >
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(isOpen && { display: isTabletOrMobile ? "none" : "inherit", opacity: "0%", pointerEvents: "none" }),
            }}
          >
            <MenuIcon
              color="inherit"
              aria-label="open drawer"
              edge="center"
              sx={{
                marginRight: isOpen ? 5 : 0,
                ...(isOpen && { pointerEvents: "none" }),
              }}
            />
          </IconButton>

          <img
            src={isDarkMode ? "/logoDark.svg" : "/logo.svg"}
            alt="logo"
            width={isTabletOrMobile ? "80px" : "100px"}
            className="max-w-full h-auto"
          />
          {/* </IconButton> */}
          {/* <Switch
            checked={isDarkMode}
            onChange={toggleDarkMode}
            inputProps={{ "aria-label": "Toggle Dark Mode" }}
            color="secondary"
            sx={{ ...(isOpen && isTabletOrMobile && { display: "none" }) }}
          /> */}
          <Box sx={{ display: "flex", alignItems: "center", ...(isOpen && isTabletOrMobile && { display: "none" }) }}>
            <IconButton color={isDarkMode ? "secondary" : ""} onClick={toggleDarkMode}>
              {isDarkMode ? <Brightness3TwoToneIcon /> : <LightModeTwoToneIcon />}
            </IconButton>
            <Switch
              checked={isDarkMode}
              onChange={toggleDarkMode}
              inputProps={{ "aria-label": "Toggle Dark Mode" }}
              color="secondary"
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HeaderComponent;
