// Libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "react-responsive";
import CssBaseline from "@mui/material/CssBaseline";

// My components
import Introduction from "./moduleParts/1_Introduction";
import LearningOutcomes from "./moduleParts/2_LearningOutcomes";
import LearningMaterial from "./moduleParts/3_LearningMaterial";
import References from "./moduleParts/4_References";
import Assessment from "./moduleParts/5_Assessment";
import WebLinks from "./moduleParts/6_WebLinks";
import CourseSlides from "./moduleParts/CourseSlides";
import Videos from "./moduleParts/7_Videos";
import { moduleData } from "../services/data";
import Quiz from "./Quiz";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// component
function SubmoduleComponent({ indexClicked, hide, DrawerHeader }) {
  const [valueLinks, setValueLinks] = useState(0);
  const [valueMaterials, setValueMaterials] = useState(0);

  useEffect(() => {
    setValueLinks(0);
    setValueMaterials(0);
  }, [indexClicked]);

  const handleChangeLinks = (event, newValue) => {
    setValueLinks(newValue);
  };
  const handleChangeMaterials = (event, newValue) => {
    setValueMaterials(newValue);
  };
  const { title, type, content } = moduleData[indexClicked];

  // Destructure only if 'content' exists
  const {
    introduction = null,
    outcomes = null,
    slides = null,
    material = null,
    references = null,
    webLinks = null,
    videos = null,
  } = content || {};
  //console.log(slide);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isBetween = useMediaQuery({ query: "(min-width: 600px) and (max-width: 1224px) and (orientation: portrait)" });
  //console.log("isBetween", isBetween);

  const marginTopFn = () => {
    if (isDesktopOrLaptop && !isPortrait) return 8;
    if (isDesktopOrLaptop && isPortrait) return 8;
    if (isBetween) return 7;
    if (isTabletOrMobile && !isPortrait) return 6;
    if (isTabletOrMobile && isPortrait) return 5;
  };

  const marginLeftFn = () => {
    if (isDesktopOrLaptop && !isPortrait) return 3;
    if (isDesktopOrLaptop && isPortrait) return 7;
    if (isBetween) return 7;
    if (isTabletOrMobile && !isPortrait) return 7;
    if (isTabletOrMobile && isPortrait) return 3.5;
  };

  const firstDigitOfIndex = indexClicked.toString().split("")[0];
  const secondDigitOfIndex = indexClicked.toString().split("")[1];

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  if (indexClicked === 60) return <Quiz marginTopFn={marginTopFn} marginLeftFn={marginLeftFn} />;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pl: 3,
        pt: 3,
        pb: 1,
        pr: 1,
        textWrap: "wrap",
        marginTop: marginTopFn(),
        marginLeft: marginLeftFn(),
        mb: 2,
        //zIndex: 0,
      }}
    >
      <CssBaseline />
      <Typography variant="h5" align="center" display="flex">
        {firstDigitOfIndex + "." + secondDigitOfIndex + " "}
        {title}
      </Typography>
      <>
        {introduction && (
          <Introduction introduction={introduction} isTabletOrMobile={isTabletOrMobile} itemCode={indexClicked} />
        )}

        {outcomes && (
          <LearningOutcomes outcomes={outcomes} isTabletOrMobile={isTabletOrMobile} itemCode={indexClicked} />
        )}

        {slides && <CourseSlides slides={slides} isTabletOrMobile={isTabletOrMobile} itemCode={indexClicked} />}
        {(material || references) && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={valueMaterials}
                onChange={handleChangeMaterials}
                aria-label="basic tabs example"
                centered
                textColor="secondary"
              >
                {!material ? (
                  <Tab
                    label="References"
                    {...a11yProps(0)}
                    sx={{
                      fontSize: {
                        xs: "15px", // up to 600px
                        sm: "16px", // 600px to 960px
                        md: "18px", // 960px to 1280px
                        lg: "20px", // 1280px to 1920px
                        xl: "20px", // above 1920px
                      },
                    }}
                  />
                ) : (
                  [
                    <Tab
                      key="learningMaterialsTab"
                      label="Learning Materials"
                      {...a11yProps(0)}
                      sx={{
                        fontSize: {
                          xs: "15px", // up to 600px
                          sm: "16px", // 600px to 960px
                          md: "18px", // 960px to 1280px
                          lg: "20px", // 1280px to 1920px
                          xl: "20px", // above 1920px
                        },
                      }}
                    />,
                    references && (
                      <Tab
                        key="referencesTab"
                        label="References"
                        {...a11yProps(1)}
                        sx={{
                          fontSize: {
                            xs: "15px", // up to 600px
                            sm: "16px", // 600px to 960px
                            md: "18px", // 960px to 1280px
                            lg: "20px", // 1280px to 1920px
                            xl: "20px", // above 1920px
                          },
                        }}
                      />
                    ),
                  ]
                )}
              </Tabs>
            </Box>
            {!material ? (
              <CustomTabPanel value={valueMaterials} index={0}>
                {references && <References references={references} isTabletOrMobile={isTabletOrMobile} />}
              </CustomTabPanel>
            ) : (
              <>
                <CustomTabPanel value={valueMaterials} index={0}>
                  {material && <LearningMaterial material={material} isTabletOrMobile={isTabletOrMobile} />}
                </CustomTabPanel>
                <CustomTabPanel value={valueMaterials} index={1}>
                  {references && <References references={references} isTabletOrMobile={isTabletOrMobile} />}
                </CustomTabPanel>
              </>
            )}
          </Box>
        )}

        {/* activities && <Assessment activities={activities} isTabletOrMobile={isTabletOrMobile} /> */}
        {(webLinks || videos) && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={valueLinks}
                onChange={handleChangeLinks}
                aria-label="basic tabs example"
                centered
                textColor="secondary"
              >
                {!webLinks ? (
                  <Tab
                    label="Videos"
                    {...a11yProps(0)}
                    sx={{
                      fontSize: {
                        xs: "15px", // up to 600px
                        sm: "16px", // 600px to 960px
                        md: "18px", // 960px to 1280px
                        lg: "20px", // 1280px to 1920px
                        xl: "20px", // above 1920px
                      },
                    }}
                  />
                ) : (
                  [
                    <Tab
                      key="WebLinksTab"
                      label="WebLinks"
                      {...a11yProps(0)}
                      sx={{
                        fontSize: {
                          xs: "15px", // up to 600px
                          sm: "16px", // 600px to 960px
                          md: "18px", // 960px to 1280px
                          lg: "20px", // 1280px to 1920px
                          xl: "20px", // above 1920px
                        },
                      }}
                    />,
                    videos && (
                      <Tab
                        key="videosTab"
                        label="Videos"
                        {...a11yProps(1)}
                        sx={{
                          fontSize: {
                            xs: "15px", // up to 600px
                            sm: "16px", // 600px to 960px
                            md: "18px", // 960px to 1280px
                            lg: "20px", // 1280px to 1920px
                            xl: "20px", // above 1920px
                          },
                        }}
                      />
                    ),
                  ]
                )}
              </Tabs>
            </Box>
            {!webLinks ? (
              <CustomTabPanel value={valueLinks} index={0}>
                {videos && <Videos videos={videos} isTabletOrMobile={isTabletOrMobile} />}
              </CustomTabPanel>
            ) : (
              <>
                <CustomTabPanel value={valueLinks} index={0}>
                  {webLinks && <WebLinks webLinks={webLinks} isTabletOrMobile={isTabletOrMobile} />}
                </CustomTabPanel>
                <CustomTabPanel value={valueLinks} index={1}>
                  {videos && <Videos videos={videos} isTabletOrMobile={isTabletOrMobile} />}
                </CustomTabPanel>
              </>
            )}
          </Box>
        )}
      </>
    </Box>
  );
}

export default SubmoduleComponent;
