import React, { useReducer, useState, useEffect } from "react";
import { Box, Button, CssBaseline, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import LinearProgressWithLabel from "./ui/LinearProgressWithLabel";
import questionsData from "../services/questions.json";
import QuizCard from "./ui/QuizCard";
import QuestionsCard from "./ui/QuestionsCard";
//import image from "../svg/bg.png";

const loadInitialState = () => {
  const savedState = localStorage.getItem("quizState");
  return savedState
    ? JSON.parse(savedState)
    : {
        currentQuestion: 0,
        answers: new Array(questionsData.length).fill(""),
        correctAnswers: 0,
        incorrectAnswers: 0,
        showResults: false,
      };
};

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "NEXT_QUESTION":
      return {
        ...state,
        currentQuestion: state.currentQuestion + 1,
      };
    case "ANSWER":
      const newAnswers = [...state.answers];
      newAnswers[state.currentQuestion] = action.payload;
      return {
        ...state,
        answers: newAnswers,
        correctAnswers: action.payload.isCorrect //=== questionsData[state.currentQuestion].correct
          ? state.correctAnswers + 1
          : state.correctAnswers,
        incorrectAnswers: action.payload.isCorrect //=== questionsData[state.currentQuestion].correct
          ? state.incorrectAnswers
          : state.incorrectAnswers + 1,
      };
    case "SHOW_RESULTS":
      return {
        ...state,
        currentQuestion: state.currentQuestion + 1,
        showResults: true,
      };
    case "LOAD_STATE":
      return {
        ...action.payload, // Load the entire state from the payload
      };
    case "RESET": // Add a RESET action
      return loadInitialState();
    default:
      return state;
  }
};

const Quiz = ({ marginLeftFn, marginTopFn }) => {
  const [state, dispatch] = useReducer(reducer, loadInitialState());
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    // Only save the state to localStorage if the results are not being shown
    if (!state.showResults) {
      localStorage.setItem("quizState", JSON.stringify(state));
    } else {
      // clear localStorage
      localStorage.removeItem("quizState");
    }
  }, [state]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitAnswer = () => {
    // console.log(
    //   selectedOption,
    //   questionsData[state.currentQuestion].correct,
    //   selectedOption === questionsData[state.currentQuestion].correct
    // );
    const isCorrect = selectedOption === questionsData[state.currentQuestion].correct;
    dispatch({ type: "ANSWER", payload: { answer: selectedOption, isCorrect: isCorrect } });
    setSelectedOption(""); // Reset selection
    if (state.currentQuestion < questionsData.length - 1) {
      dispatch({ type: "NEXT_QUESTION" });
    } else {
      dispatch({ type: "SHOW_RESULTS" });
      localStorage.removeItem("quizState");
    }
  };

  const handleReset = () => {
    dispatch({ type: "RESET" });
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 50,
        pl: 3,
        pt: 3,
        pb: 1,
        pr: 2,
        textWrap: "wrap",
        marginTop: marginTopFn(),
        marginLeft: marginLeftFn(),
        mb: 2,
      }}
    >
      <Box
        className="max-w-3xl flex flex-col m-auto "
        // sx={{
        //   backgroundImage: `URL(${image})`,
        //   backgroundSize: "cover", // Cover the entire Box area
        //   backgroundPosition: "center", // Center the background image
        //   p: 1,
        //   borderRadius: 2,
        // }}
      >
        <CssBaseline />
        <Typography variant="h6" gutterBottom>
          6.0 Assessment
        </Typography>
        <LinearProgressWithLabel value={(state.currentQuestion / questionsData.length) * 100} />
        {!state.showResults ? (
          <QuestionsCard
            questionsData={questionsData}
            currentQuestion={state.currentQuestion}
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
            handleSubmitAnswer={handleSubmitAnswer}
          />
        ) : (
          <QuizCard
            answers={state.answers}
            correctAnswers={state.correctAnswers}
            incorrectAnswers={state.incorrectAnswers}
            questionsData={questionsData}
            resetQuiz={handleReset}
          />
        )}
      </Box>
    </Box>
  );
};

export default Quiz;
