import React from "react";
// import landingImage from "../landingImage.svg";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";

const LandingMobilePortrait = ({ GoToPlatform }) => {
  const isSmall = useMediaQuery({ query: "(max-width: 320px)" });
  const isMed = useMediaQuery({ query: "(max-width: 400px)" });
  const getSize = () => {
    if (isSmall) return "small";
    if (isMed) return "medium";
  };

  const navigateToExternalWebsite = () => {
    window.location.href = "https://mashup-hei.eu/";
  };

  return (
    <div className="flex flex-col justify-start items-center m-h-[92dvh] ">
      <div className="flex max-w-[50dvw] w-[100%] justify-center">
        <img src="/landingImage.svg" className="max-w-full h-auto" alt="logo" />
      </div>

      <div className="mt-8 justify-center items-center">
        <div className="text-lg font-bold px-3 py-2 flex text-center justify-center">
          Welcome to Mashup-n-hei platform
        </div>
        <div className="justify-center text-justify px-3  min-[350px]:px-5 min-[750px]:px-7  min-[800px]:px-10 flex max-[320px]:text-xs max-[375px]:text-sm min-[400px]:text-base mb-5 min-[500px]:mb-10">
          Welcome to an innovative Social-Emotional Learning (SEL) training program aimed for educators and
          practitioners in Higher Education. This program has been created under the Erasmus+ Project entitled “MASH-up
          n' HEI Multimodal Approach for Social-emotional learning in HEI”. One of the main aims of MASH-up n' HEI is to
          build knowledge and skills drawing on SEL, music, and digital tools that professionals can use when they
          provide SEL programs to university students.
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex justify-center p-2 min-[500px]:mb-4">
            <Button variant="contained" onClick={navigateToExternalWebsite} size={getSize()}>
              Learn more
            </Button>
          </div>
          <div className="flex justify-center p-2 min-[500px]:mb-4">
            <Button variant="contained" onClick={GoToPlatform} size={getSize()}>
              Enter
            </Button>
          </div>
        </div>
      </div>
      <div className="flex max-w-lg w-[70%] justify-center mt-5">
        <img src="/erasmus.png" className="max-w-full h-auto" alt="logo" />
      </div>
    </div>
  );
};

export default LandingMobilePortrait;
