import React from "react";
import { Button, Divider } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Box, Card, Typography } from "@mui/material";

function QuizCard({ correctAnswers, incorrectAnswers, questionsData, answers, resetQuiz }) {
  return (
    <Box>
      <Card variant="outlined" className="backdrop-blur">
        <CardContent>
          <Box>
            <Typography variant="h6"> Results:</Typography>
            <Divider className="mt-2" />
            {questionsData.map((question, index) => (
              <Box key={index}>
                {console.log(answers[index], question.correct)}
                {/* <Typography style={{ color: answers[index].isCorrect ? "green" : "red" }}>
                  Question {index + 1}: {answers[index].isCorrect ? "Correct" : "Incorrect"}
                </Typography> */}
                <Typography className="my-1">
                  <i>Question {index + 1}: </i> {questionsData[index]["questionText"]}
                </Typography>
                <Typography>
                  Your Answer:
                  <span style={{ color: answers[index].isCorrect ? "green" : "red" }}> {answers[index]["answer"]}</span>
                </Typography>
                <Typography>
                  {!answers[index].isCorrect && (
                    <Typography>
                      Correct Answer: <span style={{ color: "green" }}> {question.correct}</span>
                    </Typography>
                  )}
                </Typography>
                <Divider className="my-2" />
              </Box>
            ))}
            <Typography>Correct Answers: {correctAnswers}</Typography>
            <Typography>Incorrect Answers: {incorrectAnswers}</Typography>
          </Box>
          <Button variant="contained" onClick={resetQuiz} sx={{ mt: 2 }}>
            Start Again
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default QuizCard;
