// import logo from "./logo.svg";
// import "./App.css";
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./views/LandingPage";
import PlatformPage from "./views/PlatformPage";

function App() {
  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/platform" element={<PlatformPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
