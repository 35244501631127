import React from "react";
import Button from "@mui/material/Button";

const LandingDesktop = ({ GoToPlatform }) => {
  const navigateToExternalWebsite = () => {
    window.location.href = "https://mashup-hei.eu/";
  };

  return (
    <div className="flex flex-row justify-around items-center w-[100dvw] h-[92dvh] bg-slate-100">
      <div className="w-1/2 pl-10  flex h-[92dvh] items-center">
        <div className="flex flex-col w-full">
          <h2>Welcome to Mashup-n-hei platform</h2>
          <div>
            Welcome to an innovative Social-Emotional Learning (SEL) training program aimed for educators and
            practitioners in Higher Education. This program has been created under the Erasmus+ Project entitled
            “MASH-up n' HEI Multimodal Approach for Social-emotional learning in HEI”. One of the main aims of MASH-up
            n' HEI is to build knowledge and skills drawing on SEL, music, and digital tools that professionals can use
            when they provide SEL programs to university students.
          </div>
          <div className="flex flex-row pl-5 mt-5 justify-end ">
            <Button variant="contained" onClick={GoToPlatform}>
              Enter
            </Button>
            <Button variant="contained" onClick={navigateToExternalWebsite} sx={{ ml: 2 }}>
              Learn more
            </Button>
          </div>
          <div className="flex w-1/3 mt-10 justify-end flex-col overflow-hidden align-middle">
            <img src="/erasmus.png" alt=" erasmus logo" />
          </div>
        </div>
      </div>
      <div className="flex w-1/2 justify-end flex-col overflow-hidden">
        <img src="/landingImage.svg" alt="logo" />
      </div>
    </div>
  );
};

export default LandingDesktop;
