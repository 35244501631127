import React from "react";
import { Button, Box, Link, Typography, Divider } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
//import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LinksComponent from "../ui/LinksComponent";

function allEmptyStrings(arr) {
  return arr.every((element) => element === "");
}

function CourseSlides({ slides, isTabletOrMobile, itemCode }) {
  // const { address, label, labelMobile, description = "" } = slides[0];
  const slidesHaveData = slides.length > 0 && !allEmptyStrings(slides);
  return (
    <Box sx={{ flexGrow: 1, p: isTabletOrMobile ? 0 : 1, mb: 2 }}>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
      >
        <Divider textAlign="left" sx={{ flexGrow: 1 }}>
          Module Slides
        </Divider>
      </Typography>

      {slidesHaveData &&
        slides.map((slide, index) => (
          <LinksComponent key={index + "slide"} link={slide} isTabletOrMobile={isTabletOrMobile} />
        ))}
    </Box>
  );
}

export default CourseSlides;
