import React from "react";
import { SvgIcon } from "@mui/material";

function Category21({ color, props, isDarkMode }) {
  return (
    <SvgIcon viewBox="0 0 512 512">
      {/* Maintain the viewBox of your SVG */}
      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512" {...props}>
        <path
          d="M442.75 30C457.78 30 470 42.22 470 57.25v385.5c0 15.03-12.22 27.25-27.25 27.25H57.25C42.22 470 30 457.78 30 442.75V57.25C30 42.22 42.22 30 57.25 30h385.5m0-30H57.25C25.63 0 0 25.63 0 57.25v385.5C0 474.37 25.63 500 57.25 500h385.5c31.62 0 57.25-25.63 57.25-57.25V57.25C500 25.63 474.37 0 442.75 0Z"
          fill={color}
        />
        <path
          d="M145.12 307.66h61.43c6.12 0 10.79 1.26 14 3.77s4.82 5.92 4.82 10.24c0 3.84-1.27 7.1-3.82 9.77s-6.41 4-11.59 4h-86.61c-5.88 0-10.47-1.63-13.77-4.88-3.29-3.26-4.94-7.08-4.94-11.47 0-2.82 1.06-6.57 3.18-11.24s4.43-8.34 6.94-11c10.43-10.83 19.85-20.1 28.24-27.83 8.39-7.73 14.4-12.81 18-15.24 6.43-4.55 11.79-9.12 16.06-13.71s7.53-9.3 9.77-14.12c2.24-4.82 3.35-9.55 3.35-14.18 0-5.02-1.2-9.51-3.59-13.47-2.39-3.96-5.65-7.04-9.77-9.24s-8.61-3.29-13.47-3.29c-10.28 0-18.36 4.51-24.24 13.53-.78 1.18-2.1 4.39-3.94 9.65s-3.92 9.3-6.24 12.12c-2.31 2.82-5.71 4.24-10.18 4.24-3.92 0-7.18-1.29-9.77-3.88s-3.88-6.12-3.88-10.59c0-5.41 1.22-11.06 3.65-16.94 2.43-5.88 6.06-11.22 10.88-16s10.94-8.65 18.36-11.59 16.1-4.41 26.06-4.41c12 0 22.24 1.88 30.71 5.65 5.49 2.51 10.32 5.96 14.47 10.36s7.39 9.47 9.71 15.24 3.47 11.75 3.47 17.95c0 9.73-2.41 18.57-7.24 26.54s-9.75 14.2-14.77 18.71c-5.02 4.51-13.43 11.59-25.24 21.24s-19.91 17.14-24.3 22.48c-1.88 2.12-3.8 4.67-5.77 7.65ZM272.45 338.38c-4.86 0-9.06-1.57-12.59-4.71-3.53-3.14-5.3-7.53-5.3-13.18 0-4.79 1.69-8.9 5.06-12.36 3.37-3.45 7.53-5.18 12.47-5.18s9.16 1.71 12.65 5.12 5.24 7.55 5.24 12.41c0 5.57-1.75 9.94-5.24 13.12s-7.59 4.77-12.3 4.77ZM364.19 317.5V213.23c-19.4 14.9-32.47 22.34-39.19 22.34-3.21 0-6.05-1.28-8.54-3.84-2.48-2.56-3.72-5.52-3.72-8.88 0-3.9 1.22-6.76 3.67-8.59s6.76-4.2 12.95-7.1c9.24-4.35 16.63-8.94 22.17-13.75s10.45-10.2 14.72-16.16c4.28-5.96 7.07-9.63 8.36-11s3.74-2.06 7.33-2.06c4.05 0 7.3 1.57 9.74 4.7 2.44 3.13 3.67 7.45 3.67 12.95v131.2c0 15.35-5.23 23.03-15.7 23.03-4.66 0-8.4-1.57-11.23-4.7s-4.24-7.75-4.24-13.87Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
}

export default Category21;
