import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import LayoutComponent from "../components/LayoutComponent";

function PlatformPage() {
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <LayoutComponent />
      </Box>
    </div>
  );
}

export default PlatformPage;
