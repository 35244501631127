import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, Collapse, IconButton } from "@mui/material";
import { getCollapseIntroStates, setCollapseIntroState } from "../../services/helpfulFunctions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
//import DocumentViewer from "../ui/DocumentViewer";
import LinksComponent from "../ui/LinksComponent";

function hasEmptyValues(data, attributeName) {
  // 1. Ensure data is an array
  if (!Array.isArray(data)) return false;

  // 2. Check if any item has an empty string for the specified attribute
  return data.some((item) => item[attributeName] === "");
}

function Introduction({ introduction, isTabletOrMobile, itemCode }) {
  const { sentence, documents = [] } = introduction;

  const documentsHaveData = documents.length > 0 && !hasEmptyValues(documents);
  const [isExpanded, setIsExpanded] = useState(getCollapseIntroStates(itemCode));
  // const [iframeStates, setIframeStates] = useState(documents.map(() => false));

  useEffect(() => {
    const state = getCollapseIntroStates(itemCode);
    //console.log("Effect-based expanded state:", state);
    setIsExpanded(state);
  }, [itemCode]);

  const toggleCollapse = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    setCollapseIntroState(itemCode, newState); // Update localStorage with the new state
  };

  // const handleButtonClick = (index) => {
  //   setIframeStates((prevStates) => prevStates.map((state, i) => (i === index ? !state : state)));
  // };

  return (
    <Box sx={{ flexGrow: 1, p: isTabletOrMobile ? 0 : 1, mt: isTabletOrMobile ? 2 : 5, mb: 2 }}>
      {/* Use Typography as toggle control */}
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
        onClick={toggleCollapse}
      >
        <Divider textAlign="left" sx={{ flexGrow: 1 }}>
          Introduction
        </Divider>
        {/* Change Icon based on the state */}
        {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </Typography>
      <Collapse in={isExpanded}>
        {sentence &&
          sentence.length > 0 &&
          sentence.map((text, index) => (
            <Typography key={index} paragraph sx={{ mx: isTabletOrMobile ? 1 : 2, textAlign: "justify" }}>
              {text}
            </Typography>
          ))}
        {documentsHaveData &&
          documents.map((document, index) => (
            <LinksComponent key={index} href={document.url} link={document} isTabletOrMobile={isTabletOrMobile} />
          ))}
      </Collapse>
    </Box>
  );
}

export default Introduction;
