import React from "react";
import Typography from "@mui/material/Typography";
import { Box, List, Link, ListItem, ListItemIcon } from "@mui/material";
import LinksComponent from "../ui/LinksComponent";

const hasEmptyValues = (data, attributeName) => {
  // 1. Ensure data is an array
  if (!Array.isArray(data)) return false;

  // 2. Check if any item has an empty string for the specified attribute
  return data.some((item) => item[attributeName] === "");
};

const WebLinks = ({ webLinks, isTabletOrMobile }) => {
  const linksHaveData = webLinks.length > 0 && !hasEmptyValues(webLinks, "address");
  return (
    <Box
      sx={{
        flexGrow: 1,
        p: isTabletOrMobile ? 0 : 1,
        //boxShadow: "2",
        // borderTopLeftRadius: "10%",
        // borderTopRightRadius: "10%",
        // borderBottomLeftRadius: "3%",
        // borderBottomRightRadius: "3%",
        //border: 1,
        mb: 1,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Recommended WebLinks
      </Typography>
      {linksHaveData &&
        webLinks.map((link, index) => <LinksComponent key={index} link={link} isTabletOrMobile={isTabletOrMobile} />)}
    </Box>
  );
};

export default WebLinks;
