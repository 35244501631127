import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import DrawerComputer from "./DrawerComputer";
import DrawerMobile from "./DrawerMobile";

const DrawerComponent = ({
  isOpen,
  handleDrawerClose,
  theme,
  setIndexClicked,
  DrawerHeader,
  indexClicked,
  handleOpenModal,
}) => {
  const [openItems, setOpenItems] = useState({});
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  const shouldDisplay = (item) => {
    // If item is a standalone module (type 1 with no parent category), always display
    if (item.type === 1 && ![10, 20, 30, 40, 50, 60].includes(item.code)) {
      const parentCode = Math.floor(item.code / 10) * 10;
      return openItems[parentCode];
    }
    return true; // Always display type 0 items and standalone type 1 items
  };

  const handleToggle = (code) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [code]: !prevOpenItems[code],
    }));
  };

  const handleItemClick = (code) => {
    setIndexClicked(code);
    //handleDrawerClose(!isOpen);
  };

  return isDesktopOrLaptop ? (
    <DrawerComputer
      isOpen={isOpen}
      handleDrawerClose={handleDrawerClose}
      handleOpenModal={handleOpenModal}
      theme={theme}
      DrawerHeader={DrawerHeader}
      shouldDisplay={shouldDisplay}
      openItems={openItems}
      handleItemClick={handleItemClick}
      handleToggle={handleToggle}
      indexClicked={indexClicked}
    />
  ) : (
    <DrawerMobile
      isOpen={isOpen}
      handleDrawerClose={handleDrawerClose}
      handleOpenModal={handleOpenModal}
      theme={theme}
      DrawerHeader={DrawerHeader}
      shouldDisplay={shouldDisplay}
      openItems={openItems}
      handleItemClick={handleItemClick}
      handleToggle={handleToggle}
      indexClicked={indexClicked}
    />
  );
};

export default DrawerComponent;
