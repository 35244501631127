import React from "react";
import { SvgIcon } from "@mui/material";

function Category20({ color, props, isDarkMode }) {
  return (
    <SvgIcon viewBox="0 0 512 512">
      {/* Maintain the viewBox of your SVG */}
      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512" {...props}>
        <path
          d="M500.01 113.52v323.35c0 26.52-25.64 48.02-57.25 48.02H57.25C25.64 484.89 0 463.39 0 436.87V113.52c0-.39.01-.79.02-1.18l-.02.02V36.17C0 16.19 14.61 0 32.64 0h151.08c32.22 0 63.1 16.15 82.32 44.82 3.73 5.56 8.27 9.83 13.77 11.36 7.45 2.06 11.73 5.58 14.14 9.32h148.81c31.61 0 57.25 21.5 57.25 48.02Z"
          fill={color}
        />
        <path
          d="M121.92 300.1h61.43c6.12 0 10.79 1.26 14 3.77s4.82 5.92 4.82 10.24c0 3.84-1.27 7.1-3.82 9.77s-6.41 4-11.59 4h-86.61c-5.88 0-10.47-1.63-13.77-4.88s-4.94-7.08-4.94-11.47c0-2.82 1.06-6.57 3.18-11.24s4.43-8.34 6.94-11c10.43-10.83 19.85-20.1 28.24-27.83 8.39-7.73 14.4-12.81 18-15.24 6.43-4.55 11.79-9.12 16.06-13.71s7.53-9.3 9.77-14.12 3.35-9.55 3.35-14.18c0-5.02-1.2-9.51-3.59-13.47s-5.65-7.04-9.77-9.24-8.61-3.29-13.47-3.29c-10.28 0-18.36 4.51-24.24 13.53-.78 1.18-2.1 4.39-3.94 9.65s-3.92 9.3-6.24 12.12-5.71 4.24-10.18 4.24c-3.92 0-7.18-1.29-9.77-3.88s-3.88-6.12-3.88-10.59c0-5.41 1.22-11.06 3.65-16.94 2.43-5.88 6.06-11.22 10.88-16s10.94-8.65 18.36-11.59 16.1-4.41 26.06-4.41c12 0 22.24 1.88 30.71 5.65a46.002 46.002 0 0 1 14.47 10.36c4.16 4.39 7.39 9.47 9.71 15.24s3.47 11.75 3.47 17.95c0 9.73-2.41 18.57-7.24 26.54s-9.75 14.2-14.77 18.71-13.43 11.59-25.24 21.24c-11.81 9.65-19.91 17.14-24.3 22.48-1.88 2.12-3.8 4.67-5.77 7.65ZM249.24 330.82c-4.86 0-9.06-1.57-12.59-4.71-3.53-3.14-5.3-7.53-5.3-13.18 0-4.79 1.69-8.9 5.06-12.36s7.53-5.18 12.47-5.18 9.16 1.71 12.65 5.12 5.24 7.55 5.24 12.41c0 5.57-1.75 9.94-5.24 13.12s-7.59 4.77-12.3 4.77ZM418.57 243.27c0 12.87-.78 23.93-2.35 33.18s-4.47 17.65-8.71 25.18c-5.33 9.26-12.24 16.4-20.71 21.42-8.47 5.02-17.89 7.53-28.24 7.53-11.92 0-22.61-3.37-32.07-10.12-9.45-6.75-16.57-16.08-21.36-28.01-2.43-6.51-4.22-13.71-5.35-21.59s-1.71-16.42-1.71-25.59c0-11.85.63-22.51 1.88-32.01s3.22-17.65 5.88-24.48c4.63-11.3 11.36-19.91 20.18-25.83 8.83-5.92 19.36-8.88 31.6-8.88 8.08 0 15.42 1.31 22 3.94s12.38 6.47 17.36 11.53 9.2 11.32 12.65 18.77c5.96 12.94 8.94 31.26 8.94 54.95Zm-32.24-2.35c0-14.36-.86-26.1-2.59-35.24s-4.63-16.04-8.71-20.71-9.73-7-16.94-7c-10.36 0-17.55 5.22-21.59 15.65-4.04 10.43-6.06 26.52-6.06 48.25 0 14.75.86 26.83 2.59 36.24s4.63 16.53 8.71 21.36 9.61 7.24 16.59 7.24 12.87-2.51 16.94-7.53 6.94-12.2 8.59-21.53 2.47-21.57 2.47-36.71Z"
          fill={isDarkMode ? "#eeeeee" : "#000000"}
        />
      </svg>
    </SvgIcon>
  );
}

export default Category20;
