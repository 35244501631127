import React from "react";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";

const LandingMobileLandscape = ({ GoToPlatform }) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isHeightSmall = useMediaQuery({ query: "(max-height: 300px)" });

  const navigateToExternalWebsite = () => {
    window.location.href = "https://mashup-hei.eu/";
  };

  return (
    <div className="flex flex-row justify-around items-center w-[100dvw] h-[92dvh] bg-slate-100">
      <div className={`flex w-${isHeightSmall ? "2/3" : "1/2"} max-[550px]:w-3/5  justify-center flex-col`}>
        <div className="text-lg font-bold px-3 py-2 flex text-center justify-center ">
          Welcome to Mashup-n-hei platform
        </div>
        <div className="justify-center px-4 text-justify flex text-xs min-[700px]:text-sm min-[850px]:text-base min-[850px]:px-5 max-h-[300px]:text-sm max-[300px]:text-sm h-max-350:text-sm">
          Welcome to an innovative Social-Emotional Learning (SEL) training program aimed for educators and
          practitioners in Higher Education. This program has been created under the Erasmus+ Project entitled “MASH-up
          n' HEI Multimodal Approach for Social-emotional learning in HEI”. One of the main aims of MASH-up n' HEI is to
          build knowledge and skills drawing on SEL, music, and digital tools that professionals can use when they
          provide SEL programs to university students.
        </div>
        <div className="flex flex-row mt-3  justify-evenly">
          <Button variant="contained" onClick={GoToPlatform} size="small">
            Enter
          </Button>
          <Button variant="contained" onClick={navigateToExternalWebsite} size="small">
            Learn more
          </Button>
        </div>
      </div>
      <div
        className={`flex w-${isHeightSmall ? "1/3" : "1/2"} max-[550px]:w-2/5 justify-center flex-col overflow-hidden`}
      >
        <img src="/erasmus.png" className="max-w-full h-auto w-2/3 m-auto" alt="logo" />
      </div>
    </div>
  );
};

export default LandingMobileLandscape;
