export function getCollapseIntroStates(itemId) {
  const storedStates = localStorage.getItem("collapseStatesIntro");
  if (storedStates) {
    const parsedStates = JSON.parse(storedStates);
    return parsedStates[itemId] ? parsedStates[itemId].introExpanded : true;
  } else {
    return true;
  }
}

export function getCollapseLearnStates(itemId) {
  const storedStates = localStorage.getItem("collapseStatesLearn");
  if (storedStates) {
    const parsedStates = JSON.parse(storedStates);
    return parsedStates[itemId] ? parsedStates[itemId].learnExpanded : true;
  } else {
    return true;
  }
}

export function setCollapseIntroState(itemId, introExpanded) {
  const storedStates = localStorage.getItem("collapseStatesIntro");
  const parsedStates = JSON.parse(storedStates) || {};

  parsedStates[itemId] = { introExpanded }; // Store as an object under itemId
  localStorage.setItem("collapseStatesIntro", JSON.stringify(parsedStates));
}

export function setCollapseLearnState(itemId, learnExpanded) {
  const storedStates = localStorage.getItem("collapseStatesLearn");
  const parsedStates = JSON.parse(storedStates) || {};

  parsedStates[itemId] = { learnExpanded }; // Store as an object under itemId
  localStorage.setItem("collapseStatesLearn", JSON.stringify(parsedStates));
}
