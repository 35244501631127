import React, { createContext, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getCookie, setCookie } from "./cookie"; // Import your cookie functions

const DarkModeContext = createContext({
  isDarkMode: false,
  toggleDarkMode: () => {},
});

export const DarkModeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  //const savedPreference = getCookie("darkMode");

  // Load initial dark mode preference from cookie
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedPreference = getCookie("darkMode");
    return savedPreference === "false" ? false : prefersDarkMode;
  });

  // console.log("Cookie value:", getCookie("darkMode") === "true");
  // console.log("prefersDarkMode:", prefersDarkMode);
  // console.log("Initial isDarkMode:", isDarkMode);

  const toggleDarkMode = () => {
    const newIsDarkMode = !isDarkMode;
    setCookie("darkMode", newIsDarkMode, 30); // Save to cookie with expiry
    setIsDarkMode(newIsDarkMode);
  };

  return <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>{children}</DarkModeContext.Provider>;
};

export default DarkModeContext;
