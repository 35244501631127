import Category10 from "./Category10";
import Category20 from "./Category20";
import Category21 from "./Category21";
import Category22 from "./Category22";
import Category23 from "./Category23";
import Category30 from "./Category30";
import Category31 from "./Category31";
import Category32 from "./Category32";
import Category33 from "./Category33";
import Category40 from "./Category40";
import Category50 from "./Category50";
import Category60 from "./Category60";

export const categoryMapping = {
  10: Category10,
  20: Category20,
  21: Category21,
  22: Category22,
  23: Category23,
  30: Category30,
  31: Category31,
  32: Category32,
  33: Category33,
  40: Category40,
  50: Category50,
  60: Category60,
};
