import React from "react";
import { SvgIcon } from "@mui/material";

function Category40({ color, props, isDarkMode }) {
  return (
    <SvgIcon viewBox="0 0 512 512">
      {/* Maintain the viewBox of your SVG */}
      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512" {...props}>
        <path
          d="M442.75 30C457.78 30 470 42.22 470 57.25v385.5c0 15.03-12.22 27.25-27.25 27.25H57.25C42.22 470 30 457.78 30 442.75V57.25C30 42.22 42.22 30 57.25 30h385.5m0-30H57.25C25.63 0 0 25.63 0 57.25v385.5C0 474.37 25.63 500 57.25 500h385.5c31.62 0 57.25-25.63 57.25-57.25V57.25C500 25.63 474.37 0 442.75 0Z"
          fill={color}
        />
        <path
          d="M261.54 330.82c-4.86 0-9.06-1.57-12.59-4.71-3.53-3.14-5.3-7.53-5.3-13.18 0-4.79 1.69-8.9 5.06-12.36 3.37-3.45 7.53-5.18 12.47-5.18s9.16 1.71 12.65 5.12 5.24 7.55 5.24 12.41c0 5.57-1.75 9.94-5.24 13.12-3.49 3.18-7.59 4.77-12.3 4.77ZM427.14 242.42c0 12.59-.77 23.42-2.3 32.48-1.54 9.06-4.38 17.27-8.52 24.65-5.22 9.06-11.98 16.05-20.27 20.96s-17.51 7.37-27.64 7.37c-11.67 0-22.13-3.3-31.38-9.9-9.25-6.6-16.22-15.74-20.9-27.41-2.38-6.37-4.13-13.42-5.24-21.13s-1.67-16.07-1.67-25.05c0-11.59.61-22.04 1.84-31.33s3.15-17.27 5.76-23.95c4.53-11.06 11.11-19.48 19.75-25.28s18.94-8.7 30.92-8.7c7.91 0 15.09 1.29 21.54 3.86s12.11 6.33 16.99 11.29c4.88 4.95 9 11.08 12.38 18.37 5.84 12.67 8.75 30.6 8.75 53.78Zm-31.56-2.3c0-14.05-.84-25.55-2.53-34.49-1.69-8.94-4.53-15.7-8.52-20.27s-9.52-6.85-16.58-6.85c-10.13 0-17.18 5.11-21.13 15.32s-5.93 25.95-5.93 47.22c0 14.43.84 26.26 2.53 35.47 1.69 9.21 4.53 16.18 8.52 20.9 3.99 4.72 9.41 7.08 16.24 7.08s12.59-2.46 16.58-7.37 6.79-11.94 8.41-21.08 2.42-21.11 2.42-35.93ZM151.61 310.58v-21.16H94.49c-7.21 0-12.61-1.63-16.21-4.89-3.6-3.26-5.4-7.7-5.4-13.31 0-1.44.27-2.9.8-4.38s1.33-3.03 2.39-4.66c1.06-1.63 2.16-3.2 3.3-4.72 1.14-1.52 2.54-3.37 4.21-5.58l60.3-80.67c3.94-5.31 7.19-9.16 9.73-11.55s5.59-3.58 9.16-3.58c11.53 0 17.29 6.6 17.29 19.8v88.07h4.89c5.84 0 10.52.8 14.05 2.39s5.29 5.08 5.29 10.47c0 4.4-1.44 7.61-4.32 9.61-2.88 2.01-7.21 3.02-12.97 3.02h-6.94v21.16c0 5.84-1.29 10.18-3.87 13.03s-6.03 4.27-10.35 4.27-7.68-1.46-10.3-4.38c-2.62-2.92-3.93-7.23-3.93-12.91Zm-48.93-46.65h48.93v-66.11l-48.93 66.11Z"
          fill={isDarkMode ? "#eeeeee" : "#000000"}
        />
      </svg>
    </SvgIcon>
  );
}

export default Category40;
