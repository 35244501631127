import React from "react";
import { Button, Box, Link, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function LinksComponent({ link, index, handleButtonClick, isTabletOrMobile }) {
  const { address, label, labelMobile, description = "", iconType } = link;
  return (
    <Box
      key={index}
      sx={{
        mb: 2,
        display: "flex",
        justifySelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {description && <Typography variant="body1">{description}</Typography>}
      <Box sx={{ display: "flex", justifyContent: "start", width: "100%" }}>
        <Link
          href={address}
          target="_blank"
          rel="noopener noreferrer"
          as={Button} // Render as a Button
          variant="contained"
          size="small"
          sx={{
            maxWidth: "700px",
            px: 2,
            "&:visited": {
              backgroundColor: (theme) => theme.palette.secondary.main,
              textDecoration: "none !important", // Remove underline on visited links make important
            },
          }}
          //type={iconType === "pdf" ? "application/pdf" : "text/html"}
        >
          {iconType === "pdf" && <PictureAsPdfIcon sx={{ mr: 1 }}></PictureAsPdfIcon>}
          {iconType === "video" && <PlayCircleOutlineIcon sx={{ mr: 1 }}></PlayCircleOutlineIcon>}
          {iconType === "article" && <NewspaperIcon sx={{ mr: 1 }}></NewspaperIcon>}
          {iconType === "link" && <LinkIcon sx={{ mr: 1 }}></LinkIcon>}
          <Typography variant="body" sx={{ ml: 1 }}>
            {isTabletOrMobile ? labelMobile : label}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

export default LinksComponent;
