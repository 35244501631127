import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup, Button, Box, Card, Typography, styled } from "@mui/material";
import CardContent from "@mui/material/CardContent";

const StyledFormControlLabel = styled(FormControlLabel)({
  alignItems: "flex-start",
  marginX: 0,
  marginBottom: "10px",
  ".MuiRadio-root": {
    paddingTop: "2px",
  },
  ".MuiFormControlLabel-label": {
    whiteSpace: "pre-wrap",
  },
});

function QuestionsCard({ questionsData, currentQuestion, selectedOption, handleOptionChange, handleSubmitAnswer }) {
  //console.log(questionsData, currentQuestion, selectedOption, handleOptionChange, handleSubmitAnswer);
  return (
    <Box>
      <Card variant="outlined" style={{ backgroundColor: "#ffffff33" }}>
        <CardContent>
          <Box>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <Typography variant="h5">
                Question {currentQuestion + 1}: {questionsData[currentQuestion].questionText}
              </Typography>
              <RadioGroup
                aria-label="quiz-options"
                name="quiz-options"
                value={selectedOption}
                onChange={handleOptionChange}
                sx={{ width: "100%", marginTop: "10px" }}
              >
                {questionsData[currentQuestion].options.map((option, index) => (
                  <StyledFormControlLabel key={index} value={option} control={<Radio size="small" />} label={option} />
                ))}
              </RadioGroup>
              <Button
                variant="contained"
                onClick={handleSubmitAnswer}
                disabled={!selectedOption}
                sx={{ mt: 2, width: "auto", marginX: "auto" }}
              >
                Submit Answer
              </Button>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default QuestionsCard;
