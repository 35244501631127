import React, { useState } from "react";
import { Typography, Box, Divider } from "@mui/material";
import axios from "axios";
import ActivityComponent from "../ui/ActivityComponent";

function Assessment({ activities, isTabletOrMobile }) {
  //const initialAnswersState = activities.map(() => "");
  // const [selectedOptions, setSelectedOptions] = useState(initialAnswersState);

  // const [activityFeedback, setActivityFeedback] = useState(activities.map(() => ({ error: false, helperText: " " })));

  return (
    <Box sx={{ flexGrow: 1, p: isTabletOrMobile ? 0 : 1, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        <Divider textAlign="left"> Assessment</Divider>
      </Typography>
      {activities.map((activity, index) => (
        <ActivityComponent key={index} activity={activity} index={index + 1} />
      ))}
    </Box>
  );
}

export default Assessment;
