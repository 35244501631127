import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import DocumentViewer from "../ui/DocumentViewer";
import LinksComponent from "../ui/LinksComponent";

function hasEmptyValues(data, attributeName) {
  // 1. Ensure data is an array
  if (!Array.isArray(data)) return false;

  // 2. Check if any item has an empty string for the specified attribute
  return data.some((item) => item[attributeName] === "");
}

function LearningMaterial({ material, isTabletOrMobile }) {
  const { links = [], documents = [] } = material;
  const linksHaveData = links.length > 0 && !hasEmptyValues(links);
  const documentsHaveData = documents.length > 0 && !hasEmptyValues(documents);

  const [iframeStates, setIframeStates] = useState(
    documents.map(() => false) // Initialize all iframes as closed
  );

  const handleButtonClick = (index) => {
    setIframeStates((prevStates) => prevStates.map((state, i) => (i === index ? !state : state)));
  };

  return (
    <Box sx={{ flexGrow: 1, p: isTabletOrMobile ? 0 : 1, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Learning Material
      </Typography>
      {linksHaveData &&
        links.map((link, index) => (
          <LinksComponent key={index + "links"} link={link} isTabletOrMobile={isTabletOrMobile} />
        ))}
      {/* {documentsHaveData &&
        documents.map((document, index) =>
          isTabletOrMobile ? (
            <LinksComponent
              key={index + "linksM"}
              href={document.url}
              link={document}
              isTabletOrMobile={isTabletOrMobile}
            />
          ) : (
            // Render the DocumentViewer for non-mobile devices
            <>
              {document.description && <Typography variant="body1">{document.description}</Typography>}
              <DocumentViewer
                key={index}
                document={document}
                index={index}
                iframeState={iframeStates[index]}
                handleButtonClick={handleButtonClick}
                height={isTabletOrMobile ? "150px" : "800px"}
              />
            </>
          )
        )} */}
    </Box>
  );
}

export default LearningMaterial;
