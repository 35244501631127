import React, { useContext } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
// import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import ArticleIcon from "@mui/icons-material/Article";
// import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useMediaQuery } from "react-responsive";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { categoryMapping } from "./svgComponents";

//---------------------------------------------------------
// import Category10 from "./svgComponents/Category10";
// import Category20 from "./svgComponents/Category20";
import { moduleNav } from "../services/data";
import { drawerWidth } from "../services/data";
import DarkModeContext from "../services/DarkModeContext";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

function DrawerMobile({
  isOpen,
  handleDrawerClose,
  theme,
  shouldDisplay,
  DrawerHeader,
  handleToggle,
  openItems,
  handleItemClick,
  indexClicked,
  handleOpenModal,
}) {
  const { isDarkMode } = useContext(DarkModeContext);
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(5)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    position: "fixed",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": { ...openedMixin(theme) },
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": { ...closedMixin(theme) },
    }),
  }));

  return (
    <Drawer variant={isOpen ? "permanent" : "permanent"} open={isOpen} sx={{ zIndex: 50 }}>
      <DrawerHeader
        sx={{
          backgroundColor: isDarkMode ? "rgb(18, 18, 18)" : null,
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))",
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <List>
        <ListItem disablePadding sx={{ display: "block", textWrap: "wrap" }}>
          <ListItemButton
            sx={{
              minHeight: 30,
              justifyContent: isOpen ? "center" : "center",
              paddingX: isOpen && isPortrait ? 0.5 : "auto",
              paddingY: isOpen && isPortrait ? 0.5 : "auto",
            }}
            onClick={() => handleOpenModal(true)} // Toggle for categories
          >
            <Box className={!isOpen ? null : `mr-2 ml-0`}>
              <HelpCenterIcon color="secondary" sx={{ border: `${isDarkMode ? "white" : "black"} dotted` }} />
            </Box>

            {isOpen && <ListItemText primary="Getting Started" sx={{ opacity: isOpen ? 1 : 0 }} />}
          </ListItemButton>
        </ListItem>
        {moduleNav.map((item) => {
          const ComponentToRender = categoryMapping[item.code];
          //console.log(item.colorDark);
          const isStandaloneModule = item.type === 1 && ![20, 30].includes(Math.floor(item.code / 10) * 10); // Identifying standalone modules by code
          if (item.type === 0) {
            // Type 0 items (categories) control the collapsing of their child modules
            return (
              <React.Fragment key={item.code}>
                <ListItem disablePadding sx={{ display: "block", textWrap: "wrap" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 30,
                      justifyContent: isOpen ? "flex-end" : "center",
                      paddingX: isOpen && isPortrait ? 0.5 : "auto",
                      paddingY: isOpen && isPortrait ? 0.5 : "auto",
                    }}
                    onClick={() => handleToggle(item.code)} // Toggle for categories
                  >
                    <Box className={!isOpen ? null : `mr-2 ml-0`}>
                      <ComponentToRender
                        color={isDarkMode ? item.colorDark : item.colorLight}
                        isDarkMode={isDarkMode}
                      />
                    </Box>
                    {isOpen && <ListItemText primary={item.shortTitle} sx={{ opacity: isOpen ? 1 : 0 }} />}
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            );
          } else if (isStandaloneModule || shouldDisplay(item)) {
            // Type 1 items (modules), including standalone modules or those under an open category
            return (
              <Collapse
                in={isStandaloneModule || openItems[Math.floor(item.code / 10) * 10]}
                timeout="auto"
                unmountOnExit
                key={item.code}
              >
                {(item.code === 21 || item.code === 31 || item.code === 41) && <Divider />}
                <ListItem disablePadding sx={{ display: "block", textWrap: "wrap" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 30,
                      justifyContent: isOpen ? "flex-end" : "center",
                      paddingX: isOpen && isPortrait ? 0.5 : "auto",
                      paddingY: isOpen && isPortrait ? 0.5 : "auto",
                      borderRightWidth: indexClicked === item.code ? 5 : 0,
                      borderRightColor: indexClicked === item.code ? "#0a7ad6be" : null,
                      borderRightStyle: indexClicked === item.code ? "solid" : "hidden",
                    }}
                    onClick={() => handleItemClick(item.code)}
                  >
                    <Box className={!isOpen ? null : `mr-2 ml-0`}>
                      <ComponentToRender
                        color={isDarkMode ? item.colorDark : item.colorLight}
                        isDarkMode={isDarkMode}
                      />
                    </Box>
                    {isOpen && <ListItemText primary={item.shortTitle} sx={{ opacity: isOpen ? 1 : 0 }} />}
                  </ListItemButton>
                </ListItem>
                {/* {(item.code === 23 || item.code === 33 || item.code === 42) && <Divider />} */}
              </Collapse>
            );
          }
          return null; // For items that don't match any condition (should not occur in your current setup)
        })}
      </List>
    </Drawer>
  );
}

export default DrawerMobile;
