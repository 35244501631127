// libraries
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import useScreenSize from "../hooks/useScreenSize";
import { useMediaQuery } from "react-responsive";

//components
import LandingDesktop from "../components/LandingDesktop";
import LandingMobilePortrait from "../components/LandingMobilePortrait";
import LandingMobileLandscape from "../components/LandingMobileLandscape";

const LandingPage = () => {
  // const { width, height } = useScreenSize();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  //const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  const navigate = useNavigate();

  const GoToPlatform = () => {
    setTimeout(() => {
      navigate("/platform");
    }, 200);
  };

  // useEffect(() => {
  //   console.log(width);
  //   console.log(height);
  //   if (width >= height || width >= 900) setIsLandscape(true);
  //   else setIsLandscape(false);
  // }, [width, height]);
  const landingDesktop = () => {
    if (isDesktopOrLaptop) return <LandingDesktop GoToPlatform={GoToPlatform} />;
  };

  return (
    <div>
      <header className="fixed top-0 w-full h-[4vh] bg-black z-30" />
      {/* Main content area - adjust margin to match header and footer height */}
      {/*  */}

      <main className="flex-1 pb-[4vh] pt-[4vh]">
        {/* {isDesktopOrLaptop && <p>You are a Desktop or Laptop</p>}
        {isTabletOrMobile && <p>You are a tablet or mobile phone</p>} */}
        {/* {isBigScreen && <p>You have a huge screen</p>} */}
        {/* <p>Your are in {isPortrait ? "portrait" : "landscape"} orientation</p> */}
        {isDesktopOrLaptop && <LandingDesktop GoToPlatform={GoToPlatform} />}
        {isTabletOrMobile && isPortrait && <LandingMobilePortrait GoToPlatform={GoToPlatform} />}
        {isTabletOrMobile && !isPortrait && <LandingMobileLandscape GoToPlatform={GoToPlatform} />}
        {/* Your content */}
      </main>
      <footer className="fixed bottom-0 w-full h-[4vh] bg-black z-30" />
    </div>
  );
};

export default LandingPage;
