import React from "react";
import Typography from "@mui/material/Typography";
import { Box, List, Link, ListItem, ListItemIcon } from "@mui/material";
import LinksComponent from "../ui/LinksComponent";

function allEmptyStrings(arr) {
  return arr.every((element) => element === "");
}

function Videos({ videos, isTabletOrMobile }) {
  const { embedded = [], links = [] } = videos;

  const linksHaveData = links.length > 0 && !allEmptyStrings(links);
  const embeddedHaveData = embedded.length > 0 && !allEmptyStrings(embedded);

  const iframeStyles = {
    width: isTabletOrMobile ? "100%" : "50%",
    height: isTabletOrMobile ? "200px" : "400px", // Adjust heights as needed
    position: "relative",
    overflow: "hidden",
  };

  //console.log(embeddedHaveData, linksHaveData);
  return (
    <Box component="main" sx={{ flexGrow: 1, p: isTabletOrMobile ? 0 : 1 }}>
      <Typography variant="h6" gutterBottom>
        Recommended Videos
      </Typography>
      {linksHaveData &&
        links.map((link, index) => <LinksComponent key={index} link={link} isTabletOrMobile={isTabletOrMobile} />)}

      {embeddedHaveData &&
        embedded.map((video, index) => {
          return (
            <div key={index + "eVideos"} style={iframeStyles} className="flex justify-center items-center my-2 mx-auto">
              {/* <video controls className="max-w-3xl w-11/12 mx-auto">
                <source src={"https://www.youtube.com/embed/ouXhi_CfBVg"} type="video/mp4" />
              </video> */}
              <iframe
                //className="max-w-3xl w-full mx-auto"
                src={video.source}
                style={{ width: "100%", height: "100%" }}
                title={video.title}
                name={video.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                allowFullScreen={true}
                referrerPolicy="origin-when-cross-origin"
                loading="lazy"
              ></iframe>
              {video.description && <Typography variant="body1">{video.description}</Typography>}
            </div>
          );
        })}
    </Box>
  );
}

export default Videos;
