import React, { useState, useContext, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import HeaderComponent from "./HeaderComponent";
import DrawerComponent from "./DrawerComponent";
import SubmoduleComponent from "./SubmoduleComponent";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import DarkModeContext from "../services/DarkModeContext";
import { Button, Modal, Box, Typography, Checkbox, FormControlLabel } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const getIsOpenFromLocalStorage = () => {
  const isOpen = localStorage.getItem("isOpen");
  return isOpen !== null ? isOpen === "true" : false;
};

const getDoNotShowAgainFromLocalStorage = () => {
  return localStorage.getItem("doNotShowAgain") === "true";
};

const LayoutComponent = () => {
  const [isOpen, setIsOpenState] = useState(getIsOpenFromLocalStorage());
  const [indexClicked, setIndexClicked] = useState(10);
  const { isDarkMode } = useContext(DarkModeContext);

  const [openModal, setOpenModal] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(getDoNotShowAgainFromLocalStorage());

  useEffect(() => {
    if (!doNotShowAgain) {
      setOpenModal(true);
    }
  }, [doNotShowAgain]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setDoNotShowAgain(isChecked);
    localStorage.setItem("doNotShowAgain", isChecked);
  };

  const setIsOpen = (state) => {
    localStorage.setItem("isOpen", state);
    setIsOpenState(state);
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light", // Update dynamically
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        custom1: 1440, // Example custom breakpoint at 1440px
      },
    },
    typography: {
      h5: {
        fontSize: 18,
        fontWeight: 500,
        "@media (min-width:600px)": {
          fontSize: 20, // Increase font size for screens wider than sm breakpoint
        },
        "@media (min-width:960px)": {
          fontSize: 24, // Increase font size for screens wider than md breakpoint
        },
        "@media (min-width:1440px)": {
          fontSize: 26, // Increase font size for screens wider than custom1 breakpoint
        },
      },
      h6: {
        fontSize: 16,
        "@media (min-width:600px)": {
          fontSize: 18, // Increase font size for screens wider than sm breakpoint
        },
        "@media (min-width:960px)": {
          fontSize: 22, // Increase font size for screens wider than md breakpoint
        },
        "@media (min-width:1440px)": {
          fontSize: 24, // Increase font size for screens wider than custom1 breakpoint
        },
      },
      h4: {
        fontSize: 15,
        "@media (min-width:600px)": {
          fontSize: 16, // Increase font size for screens wider than sm breakpoint
        },
        "@media (min-width:960px)": {
          fontSize: 17, // Increase font size for screens wider than md breakpoint
        },
        "@media (min-width:1440px)": {
          fontSize: 20, // Increase font size for screens wider than custom1 breakpoint
        },
      },
      body1: {
        fontSize: 14,
        "@media (min-width:600px)": {
          fontSize: 15, // Increase font size for screens wider than sm breakpoint
        },
        "@media (min-width:960px)": {
          fontSize: 16, // Increase font size for screens wider than md breakpoint
        },
        "@media (min-width:1440px)": {
          fontSize: 18, // Increase font size for screens wider than custom1 breakpoint
        },
      },
    },
  });

  const DrawerHeader = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    backgroundColor: "#1976d2",
    ...theme.mixins.toolbar,
  }));

  const handleDrawerOpen = () => {
    //console.log("Open");
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    //console.log("Close");
    setIsOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent isOpen={isOpen} handleDrawerOpen={handleDrawerOpen} DrawerHeader={DrawerHeader} />
      <DrawerComponent
        DrawerHeader={DrawerHeader}
        handleDrawerClose={handleDrawerClose}
        handleOpenModal={handleOpenModal}
        isOpen={isOpen}
        theme={theme}
        setIndexClicked={setIndexClicked}
        indexClicked={indexClicked}
      />
      <SubmoduleComponent indexClicked={indexClicked} hide={isOpen} DrawerHeader={DrawerHeader} />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        theme={theme}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6">
            Welcome to the MashUp N HEI Platform.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            The learning material consists of 5 modules and an assessment module (6). You can quickly access the modules
            using the burger menu on the side. You can extend the menu to view more information about the modules by
            interacting with the burger menu icon. <br />
            <br /> If this is your first time here and would like to orient yourself, we suggest you focus on the
            introduction, the learning outcomes and the main presentation found under "module slides" in each module.
            Once you have a general understanding of each module you can dive into the extended materials under
            "Learning Materials" in each module. You can find additional complementary material under "Recommended
            WebLinks" and "Videos" tabs in each module. Module 6 is the assessment module. This assessment covers items
            from the main presentations of each module.
            <br />
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={doNotShowAgain} onChange={handleCheckboxChange} name="doNotShowAgain" />}
            label="Don't show again"
          />
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default LayoutComponent;
