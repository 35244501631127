import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, Collapse, List, ListItem, ListItemIcon } from "@mui/material";
import { getCollapseLearnStates, setCollapseLearnState } from "../../services/helpfulFunctions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
// import InfoIcon from "@mui/icons-material/Info";
//import { useTheme } from "@mui/material/styles";

function allEmptyStrings(arr) {
  return arr.every((element) => element === "");
}

const alphabet = "abcdefghijklmnopqrstuvwxyz";

function LearningOutcomes({ outcomes, isTabletOrMobile, itemCode }) {
  const { sentence, options } = outcomes;
  const [isExpanded, setIsExpanded] = useState(getCollapseLearnStates(itemCode));

  useEffect(() => {
    //console.log(itemCode);
    const state = getCollapseLearnStates(itemCode);
    //console.log("Effect-based expanded state:", state);
    setIsExpanded(state);
  }, [itemCode]);

  const toggleCollapse = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    setCollapseLearnState(itemCode, newState);
  };

  const optionsHasData = options.length > 0 && !allEmptyStrings(options);

  return (
    <Box sx={{ flexGrow: 1, p: isTabletOrMobile ? 0 : 1, mb: 2 }}>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
        onClick={toggleCollapse}
      >
        <Divider textAlign="left" sx={{ flexGrow: 1 }}>
          Learning Outcomes
        </Divider>
        {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </Typography>
      <Collapse in={isExpanded}>
        {sentence && <Typography>{sentence}</Typography>}
        {optionsHasData && (
          <List>
            {options.map((item, index) => (
              <ListItem key={index + "l"} disablePadding>
                <Box sx={{ display: "flex" }}>
                  <ListItemIcon sx={{ mr: 0, justifyContent: "center", minWidth: isTabletOrMobile ? "20px" : "30px" }}>
                    {"(" + alphabet[index].toUpperCase() + ")"}
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ px: 0.5, mb: 1, textAlign: "justify", justifyContent: "center" }}
                  >
                    {item}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        )}
      </Collapse>
    </Box>
  );
}

export default LearningOutcomes;
