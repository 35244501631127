import { Description } from "@mui/icons-material";

export const moduleData = {
  10: {
    title: "Introduction and Course Overview",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "How do we create an inclusive context that promotes lifelong learning opportunities? We need a holistic understanding of (a) Social and Emotional Learning (SEL), (b) Learning difficulties, (c) Motivation in learning, (d) The cognitive behavioral approach, (e) Music intervention, (f) Digital tools, and, above all, (g) Α way to apply all these concepts in a practical way!",
          "Please take a look at the introductory slides for the course.",
        ],
        documents: [
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/Introductory_slides.php",
            label: "Introductory slides",
            labelMobile: "Introductory slides",
            iconType: "pdf",
          },
        ],
      },
    },
  },
  20: {
    title: "Theoretical framework of the course",
    type: "category",
  },
  21: {
    title: "Social and emotional learning in higher education (SEL)",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "Social and emotional learning (SEL) consists of several elements related to interpersonal and intrapersonal skills and attitudes such as self-awareness, self-management, responsible decision making, social awareness, and relationship skill. The social and emotional skills that are most relevant to higher education students are those that can promote their personal and interpersonal awareness and competence, and therefore help them to cope with new and challenging academic, social, and emotional circumstances. These competencies are highly demanding for students with disabilities, since universal SEL programs usually are not adapted to the needs of marginalized and minoritized students. Nevertheless, certain types of SEL programs have been proven to be effective and beneficial for higher education.",
        ],
      },
      outcomes: {
        sentence: "After the end of this module, students are expected to: ",
        options: [
          "Be familiarized with the core elements of SEL according to CASEL framework.",
          "Understand how and why social and emotional learning is integrated and connected in higher education",
          "Comprehend the different levels of the implementation of SEL programs and their relation to the Multi-Tiered System of Supports framework and the Universal Design for Learning",
          "Delve into different SEL programs and understand the prerequisites for the participation of LD students",
        ],
      },
      material: {
        links: [
          {
            address: "https://www.sciencedirect.com/science/article/pii/S2773233923000086",
            label: "Towards an inclusive social and emotional learning",
            labelMobile: "Inclusive social and emotional learning",
            iconType: "article",
          },
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/SEL_in_Higher_Education.php",
            label: "SEL in Higher Education",
            labelMobile: "SEL in Higher Education",
            iconType: "article",
          },
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/SEL_and_learning_disabilities.php",
            label: "The connection between social emotional learning and learning disabilities",
            labelMobile: "SEL and learning disabilities",
            iconType: "pdf",
          },
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/Schoolwide_SEL_and_other_frameworks.php",
            label: "Connecting schoolwide SEL with other school-based frameworks",
            labelMobile: "Schoolwide SEL and other frameworks",
            iconType: "pdf",
          },
        ],
      },
      slides: [
        {
          address: "https://ss3d-demos.eu/documents/mashup_n_hei/Social_and_emotional_learning_(SEL).php",
          label: "Presentation: Social and emotional learning (SEL) in higher education",
          labelMobile: "Social and emotional learning (SEL)",
          iconType: "pdf",
        },
      ],
      references: {
        links: [
          {
            address: "https://www.mdpi.com/2254-9625/10/3/61",
            label: "Integrating Social Emotional Learning Strategies in Higher Education",
            labelMobile: "SEL Learning Strategies in HIE",
            iconType: "article",
          },
          {
            address: "https://www.jstor.org/stable/44219019",
            label: "Social and Emotional Learning as a Public Health Approach to Education",
            labelMobile: "SEL as Public Health Approach",
            iconType: "article",
          },
          {
            address:
              "https://ss3d-demos.eu/documents/mashup_n_hei/Integration_of_SEL_and_career_and_workforce_development.php",
            label: "A developmental framework for the integration of SEL and career and workforce development",
            labelMobile: "Integration of SEL and career and workforce development",
            iconType: "pdf",
          },
        ],
        // documents: [

        // ],
      },
      webLinks: [
        { address: "https://casel.org", label: "CASEL", labelMobile: "CASEL", iconType: "link" },
        {
          address: "https://insidesel.com/2023/03/08/sel-in-higher-education-an-overview/",
          label: "INSIDESEL",
          labelMobile: "INSIDESEL",
          iconType: "link",
        },
        {
          address: "https://mtss4success.org/",
          label: "Centre on Multi-Tiered System of Support",
          labelMobile: "MTSS Center",
          iconType: "link",
        },
        {
          address: "https://udlguidelines.cast.org/",
          label: "The UDL Guidelines",
          labelMobile: "The UDL Guidelines",
          iconType: "link",
        },
        {
          address: "https://www.secdlab.org/",
          label: "Social-Emotional and Character Development (SECD) Lab",
          labelMobile: "SECD Lab",
          iconType: "link",
        },
        {
          address: "https://intensiveintervention.org/audience/higher-education-faculty",
          label: "National Centre on Intensive Intervention",
          labelMobile: "NCII",
          iconType: "link",
        },
        {
          address: "https://insidesel.com/research-guides/sel-in-higher-ed/",
          label: "SEL in Higher Ed [Research Guide]",
          labelMobile: "SEL in Higher Ed [Research Guide]",
          iconType: "link",
        },
      ],
      videos: {
        embedded: [
          { source: "https://www.youtube.com/embed/ouXhi_CfBVg?si=njAUxdhYhIAcQeSF", label: "YouTube video player" },
        ],
        links: [
          {
            address:
              "https://casel.org/educating-future-ready-students-webisodes/#webisode-3-framework-for-sel-and-workforce-preparation",
            label: "Webisode 3: Framework for SEL and Workforce Preparation",
            labelMobile: "SEL and Workforce Preparation",
            iconType: "video",
          },
          {
            address: "https://www.youtube.com/@SELonTV",
            label: "CASEL @SELonTV",
            labelMobile: "CASEL @SELonTV",
            description: "The channel of CASEL in YouTube includes a variety of videos connected to SEL.",
            iconType: "video",
          },
        ],
      },
    },
  },
  22: {
    title: "Difficulties in learning in higher education and levels of prevention and intervention (LD)",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "Disabilities is not just a health problem, but an umbrella term, covering impairments, activity limitations, and participation restrictions in education. Practices and interventions to remove environmental and social barriers constitute a challenge in higher education for students with different types of disabilities. Individual and contextual factors that foster inclusion are discussed including frameworks of disability inclusion related to the transition in HE, personal characteristics, academic interventions and counselling support.",
        ],
      },
      outcomes: {
        sentence: "After the end of this module, students are expected to",
        options: [
          "Understand the variety of disabilities that might students from being successful learners",
          "Comprehend the importance of transition from high school to secondary education and the practices that facilitate to overcome barriers during this process",
          "Delve into interventions and models that promote inclusion in higher education",
          "Revise key findings related to student and institution-level factors that influence students’ attendance",
        ],
      },
      material: {
        links: [
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/Inclusive_education_in_higher_education.php",
            label: "Inclusive education in higher education: challenges and opportunities",
            labelMobile: "Inclusive education in higher education",
            iconType: "article",
          },
          {
            address: "https://inclusivehe.org/disability-inclusion/",
            label: "Disability Inclusion",
            labelMobile: "Disability Inclusion",
            iconType: "article",
          },
          {
            address:
              "https://www.ldonline.org/ld-topics/college-college-prep/learning-disabilities-issues-higher-education",
            label: "Learning Disabilities: Issues in Higher Education",
            labelMobile: "LD: Issues in Higher Education",
            iconType: "article",
          },
          {
            address:
              "https://journals.sagepub.com/doi/pdf/10.1177/07319487221111099?casa_token=moIdocpNXaUAAAAA:0cg0Llmcdh73mMq2hDY_8i_dS3vinlkBcez-cOHJFUQFclQ1Ml0dzs-qpcLhqhK4iton5RwmWwXb",
            label: "Considerations for Transition From High School to Postsecondary Education",
            labelMobile: "Transition From High School to HEI",
            iconType: "article",
          },
          {
            address: "https://link.springer.com/article/10.1007/s10734-023-01162-1",
            label: "Higher education and the flipped classroom approach: efficacy for students with a history of LD",
            labelMobile: "HEI and flipped classroom and LD",
            iconType: "article",
          },
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/NJCLD_transition_to_HEI.php",
            label: "NJCLD transition to HEI",
            labelMobile: "NJCLD transition to HEI",
            iconType: "pdf",
          },
        ],

        // documents: [
        // ],
      },
      slides: [
        {
          address: "https://ss3d-demos.eu/documents/mashup_n_hei/Responsive_environments_and_inclusive_practices.php",
          label:
            "Presentation: Difficulties in learning in higher education. Responsive,environments and inclusive practices.",
          labelMobile: "Responsive,environments and inclusive practices.",
          iconType: "pdf",
        },
      ],
      references: {
        links: [
          {
            address:
              "https://www.timeshighereducation.com/campus/checklist-making-disability-inclusion-reality-higher-education",
            label: "A checklist for making disability inclusion a reality in higher education",
            labelMobile: "A checklist for disability inclusion ",
            iconType: "article",
          },
        ],
      },
      webLinks: [
        { address: "https://www.ahead.org/home", label: "AHEAD", labelMobile: "AHEAD", iconType: "link" },
        {
          address: "https://njcld.org/",
          label: "National Joint Committee on Learning Disabilities (NJCLD)",
          labelMobile: "NJCLD",
          iconType: "link",
        },
        { address: "https://www.ldonline.org/", label: "LD OnLine", labelMobile: "LD OnLine", iconType: "link" },
        {
          address: "https://eurydice.eacea.ec.europa.eu/national-education-systems",
          label: "Eurydice Network",
          labelMobile: "Eurydice Network",
          iconType: "link",
        },
        {
          address: "https://esu-online.org/disabilities-students-higher-education/",
          label: "European Student’s Union",
          labelMobile: "European Student’s Union",
          iconType: "link",
        },
      ],
      videos: {
        embedded: [
          { source: "https://player.vimeo.com/video/685510915?h=07664bbbe1", label: "vimeo" },
          { source: "https://www.youtube.com/embed/kSz_zjiS3E8?si=1E3dsK2N2FIKST96", label: "YouTube video player" },
          { source: "https://www.youtube.com/embed/d153K8aEjmw?si=vU8uFUMU_0lq3gXA", label: "YouTube video player" },
        ],
      },
    },
  },
  23: {
    title:
      "How to promote intrinsic motivation for students with difficulties in learning: lessons learned by Self Determination Theory (SDT)",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "According to SDT, learners have a natural intrinsic tendency to explore their environments, to grow, learn and develop. An important goal of education then is to cultivate this inherent interest that exists within the learner. Conditions supporting the individual’s experience of autonomy, competence, and relatedness are argued to foster high quality forms of motivation and engagement for learning activities both for students with and for students without difficulties in learning.",
        ],
      },
      outcomes: {
        sentence: "After the end of this module, students will",
        options: [
          "Understand the nature of intrinsic motivation and autonomous motivation",
          "Identify the three basic psychological needs and their manifestations",
          "Gain a sense of how to apply SDT to practical issues relating in the classroom",
        ],
      },
      material: {
        links: [
          {
            address: "https://doi.org/10.1177/08295735211055355",
            label: "Applying SDT in education",
            labelMobile: "Applying SDT in education",
            iconType: "article",
          },
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/Autonomy_support_for_students_with_disabilities.php",
            label: "Autonomy support for students with disabilities",
            labelMobile: "Autonomy support for students with disabilities",
            description:
              "How to promote intrinsic motivation for students with difficulties in learning: Lessons learned from Self Determination Theory (SDT)",
            iconType: "pdf",
          },
        ],
      },
      slides: [
        {
          address: "https://ss3d-demos.eu/documents/mashup_n_hei/Presentation_SDT_and_education.php",
          label: "Presentation: SDT and education",
          labelMobile: "Presentation: SDT and education",
          iconType: "pdf",
        },
      ],
      references: {
        links: [
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/SDT_and_learners_with_disabilities.php",
            label: "SDT and learners with disabilities",
            labelMobile: "SDT and learners with disabilities",
            iconType: "pdf",
          },
        ],
      },
      webLinks: [
        {
          address: "https://selfdeterminationtheory.org/research/disability/",
          label: "SDT research on learning disabilities",
          labelMobile: "SDT research on learning disabilities",
          description: "Find access to a wealth of SDT-related research articles on learning disabilities",
          iconType: "article",
        },
      ],
      videos: {
        links: [
          {
            address: "https://vimeo.com/460358300",
            label: "SDT and music mentoring",
            labelMobile: "SDT and music mentoring",
            description:
              "A platform uses SDT to deliver music mentoring programs for young people facing a variety of challenging circumstances, such as school refusal, disruptive behaviours, lack of motivation, anxiety or depression, being at risk of exclusion",
            iconType: "video",
          },
        ],
      },
    },
  },
  30: {
    title: "Music as an effective tool in SEL for People with LD",
    type: "category",
  },
  31: {
    title: "Cognitive-behavioral approach in intervention",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "The cognitive-behavioral approach is about solving the client's current problems by changing dysfunctional thinking patterns and behavior. This is accomplished by identify dysfunctional beliefs, thinking errors and maladaptive behaviors that maintain them. CBT as a therapy, can be applied in numerous psychological disorders, such as anxiety, eating disorders, affective disorders, etc.",
        ],
      },
      outcomes: {
        sentence: "After the end of this module, students will",
        options: [
          "Understand the basic assumptions of CBT",
          "Identify the common mind traps leading to thinking errors",
          "Gain insight about specific intervention techniques (e.g. ABC technique)",
        ],
      },
      slides: [
        {
          address: "https://ss3d-demos.eu/documents/mashup_n_hei/Slides_on_CBT.php",
          label: "Slides on Cognitive Behavioral Therapy",
          labelMobile: "Slides on CBT",
          iconType: "pdf",
        },
      ],

      // material: {
      //   documents: [

      //   ],
      // },
      references: {
        links: [
          {
            address:
              "https://www.mind.org.uk/information-support/drugs-and-treatments/talking-therapy-and-counselling/cognitive-behavioural-therapy-cbt/",
            label: "mind.org.uk - more information on Cognitive Behavior Therapy",
            labelMobile: "mind.org.uk - more information on CBT",
            iconType: "link",
          },
          {
            address: "https://en.wikipedia.org/wiki/Cognitive_behavioral_therapy",
            label: "Cognitive Behavior Therapy - Wikipedia page",
            labelMobile: "Cognitive Behavior Therapy - Wikipedia page",
            iconType: "article",
          },
          {
            address: "https://journals.sagepub.com/doi/full/10.1177/1755738012471029",
            label: "The key preinciples of CBT (Fenn et al., 2013) - open access article",
            labelMobile: "The key preinciples of CBT (Fenn et al., 2013)",
            iconType: "article",
          },
        ],
      },
      webLinks: [
        {
          address: "http://www.mindovermood.com",
          label: "Mind over mood website (CBT materials)",
          labelMobile: "Mind over mood website",
          iconType: "link",
        },
        {
          address: "https://www.anxietycanada.com",
          label: "Anxiety Canada website (CBT materials)",
          labelMobile: "Anxiety Canada website",
          iconType: "link",
        },
      ],
    },
  },
  32: {
    title: "The role of music intervention",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "Music is a unique and universal cultural phenomenon that is capable of strongly engaging us physically, emotionally, and cognitively. At the same time, music is deeply rooted in social interactions, making music an ideal tool for various kinds of interventions. Music therapy is the clinical and evidence-based use of music interventions to accomplish individualized goals within a therapeutic relationship, addressing goals such as promoting wellness, managing stress, expressing feelings and improving communication.",
        ],
      },
      outcomes: {
        sentence: "After the end of this module, students will",
        options: [
          "Have a better understanding of the cognitive and neurobiological background of music.",
          "Understand how the cognitive and neural aspects are applied in the interventions.",
          "Identify the different types of music therapy.",
        ],
      },
      slides: [
        {
          address: "https://ss3d-demos.eu/documents/mashup_n_hei/Slides_on_The_role_of_music.php",
          label: "Slides on The role of music in intervention",
          labelMobile: "Slides on The role of music",
          iconType: "pdf",
        },
      ],
      references: {
        links: [
          {
            address: "https://musicpsychology.co.uk/",
            label: "Music psychology resources (dr. Victoria Williamson)",
            labelMobile: "Music psychology resources",
            iconType: "link",
          },
          {
            address: "https://www.voices.no/",
            label: "Voices: a world forum for music therapy (journal)",
            labelMobile: "Voices - music therapy journal",
            iconType: "link",
          },
        ],
      },
      webLinks: [
        {
          address: "https://en.wikipedia.org/wiki/Music_psychology",
          label: "Music psychology wikipedia page",
          labelMobile: "Music psychology wikipedia page",
          iconType: "article",
        },
      ],
      videos: {
        embedded: [
          { source: "https://www.youtube.com/embed/KVX8j5s53Os?si=T5gBaNR5Xx6Stkrc", label: "YouTube video player" },
          { source: "https://www.youtube.com/embed/jPDKi-i618U?si=a-odPFjTPxsEfnfF", label: "YouTube video player" },
          { source: "https://www.youtube.com/embed/NlY4yCsGKXU?si=yzrVO2fj8z9SbLi6", label: "YouTube video player" },
        ],
        links: [
          {
            address: "https://www.youtube.com/channel/UCVZX4GrPVKm4hhHl-VBWifQ/playlists",
            label: "Videos on music psychology",
            labelMobile: "Videos on music psychology",
            iconType: "video",
          },
        ],
      },
    },
  },
  33: {
    title: "Digital tools in multimodal SEL training",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "Digital tools, such as smart phones, tablets and laptops are pervasively part of our everyday life, so the question arises if we could employ them to promote mental health and well-being. In order to answer this question, we evaluate the pros and cons of using digital tools for therapeutic purposes, look at their effectiveness, and review some of the existing digital applications.",
        ],
      },
      outcomes: {
        sentence: "After the end of this module, students will",
        options: [
          "Have a better understanding of what are some digital tools used in training and intervention.",
          "Be able to identify the pros and cons of using digital tools.",
          "Understand how digital tools can be integrated in music therapy techniques to support accessibility.",
        ],
      },
      slides: [
        {
          address: "https://ss3d-demos.eu/documents/mashup_n_hei/Slides_on_Digital_tools.php",
          label: "Slides on Digital tools in SEL training",
          labelMobile: "Slides on Digital tools",
          iconType: "pdf",
        },
      ],

      // material: {
      //   documents: [

      //   ],
      // },
      references: {
        links: [
          {
            address: "https://casel.org/fundamentals-of-sel/",
            label: "CASEL website - SEL resources",
            labelMobile: "CASEL website - SEL resources",
            iconType: "link",
          },
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/Digital_tools_in_SEL_training.php",
            label: "Digital tools in SEL training (open access article)",
            labelMobile: "Digital tools in SEL training",
            iconType: "pdf",
          },
          {
            address: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5727354/",
            label: "Review on digital CBT tools (open access article)",
            labelMobile: "Review on digital CBT tools",
            iconType: "article",
          },
        ],
      },
      videos: {
        embedded: [
          { source: "https://www.youtube.com/embed/Uibty3ToYgA?si=Ow9GAt6vnjpnN0dr", label: "YouTube video player" },
          { source: "https://www.youtube.com/embed/pfrH9us0iKs?si=3fRt5dLufraRpkXj", label: "YouTube video player" },
          { source: "https://www.youtube.com/embed/o5ItaxrD7QQ?si=XfKCO1wCz2MNBVm2", label: "YouTube video player" },
        ],
      },
    },
  },
  40: {
    title: "Case Study: Basic elements of intervention",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "The success of Higher Education as an effective learning environment rests on establishing an inclusive context that promotes life-long learning opportunities. Socio - Emotional Learning (SEL) skills are considered as fundamental competencies for young adults’ development: crucial for individuals’ academic achievement, mental health and life success. Although universal SEL programs (e.g. cognitive behavioral/ CBT) have produced promising findings, their administration lacks success in populations with diverse abilities (e.g. learning difficulties/LD). There is growing literature indicating the potential of incorporating music and music therapy strategies as an effective medium for SEL programs in people with LD. This module  presents the development of a multimodal (music-based) social-emotional skills program, to meet the needs of university students with diverse abilities (undergraduate students with and without LD).",
        ],
      },
      outcomes: {
        sentence: "After the end of this module, students will",
        options: [
          "Understand socio-emotional learning skills as a multimodal process",
          "Identify the main components of a multimodal (music-based) SEL program ",
          "Gain familiarity with the application of music strategies in a multimodal SEL program according to learners’ needs.",
        ],
      },
      slides: [
        {
          address: "https://ss3d-demos.eu/documents/mashup_n_hei/Slides_on_music_and_SEL_program.php",
          label: "Slides on music and SEL program",
          labelMobile: "Slides on music and SEL program",
          description:
            "The development of a music-based socio-emotional skills program: Lessons learned from the field.",
          iconType: "pdf",
        },
      ],
      material: {
        links: [
          {
            address: "https://www.frontiersin.org/journals/psychology/articles/10.3389/fpsyg.2019.00423/full",
            label: "Songwriting to promote mental health.",
            labelMobile: "Songwriting to promote mental health.",
            iconType: "article",
          },
          {
            address: "https://www.frontiersin.org/journals/psychiatry/articles/10.3389/fpsyt.2023.1183311/full",
            label: "Online group music therapy",
            labelMobile: "Online group music therapy",
            description: "Description of on online group music therapy research program for undergraduate students",
            iconType: "article",
          },
        ],
      },
      references: {
        links: [
          {
            address: "https://musicgoat.com/5-songwriting-tips-techniques",
            label: "Tips on songwriting for musicians",
            labelMobile: "Tips on songwriting for musicians",
            description: "Available services and strategies to develop musicanship and songwriting abilities.",
            iconType: "link",
          },
          {
            address: "https://blog.landr.com/10-songwriting-techniques/",
            label: "Practical tips on songwriting (beginners)",
            labelMobile: "Practical tips on songwriting (beginners)",
            description: "Easy-to-follow practical ideas and resources to master songwriting.",
            iconType: "link",
          },
          {
            address:
              "http://www.therabeat.com/news-and-events/2020/4/24/the-emotional-benefits-of-songwriting-among-the-adolescent-populationnbsp",
            label: "Songwriting: examples from the field",
            labelMobile: "Songwriting: examples from the field",
            description: "The emotional benefits of songwriting among the adolescent population.",
            iconType: "link",
          },
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/STD_mindfulness_and_motivation.php",
            label: "STD, mindfulness and motivation",
            labelMobile: "STD, mindfulness and motivation",
            iconType: "pdf",
          },
        ],
      },
      webLinks: [
        {
          address: "https://www.unr.edu/counseling/virtual-relaxation-room/releasing-stress-through-the-power-of-music",
          label: "Releasing stress through music",
          labelMobile: "Releasing stress through music",
          description: "Music Resources and musical selections for relaxation and stress management",
          iconType: "link",
        },
      ],
    },
  },
  50: {
    title: "Summarizing key elements of the course",
    type: "module",
    content: {
      introduction: {
        sentence: [
          "The challenge set at the beginning of the course was to answer the question of how we create an inclusive context that promotes lifelong learning opportunities. We claimed that what is needed is a holistic understanding of (a) Social and Emotional Learning (SEL), (b) Learning difficulties, (c) Motivation in learning, (d) The cognitive behavioral approach, (e) Music interventions, (f) Digital tools, and, above all, (g) Α way to apply all these concepts in a practical way!",
          "What did we learn about all these different topics? Please take a look at the concluding slides for the course.",
        ],
        documents: [
          {
            address: "https://ss3d-demos.eu/documents/mashup_n_hei/Concluding_slides.php",
            label: "Concluding slides ",
            labelMobile: "Concluding slides",
            iconType: "pdf",
          },
        ],
      },
    },
  },
  60: {
    title: "Assessment",
    type: "module",
  },
};

export const moduleNav = [
  { shortTitle: "Introduction", code: 10, type: 1, colorLight: "#303030", colorDark: "#ffffff" },
  { shortTitle: "Theoretical framework", code: 20, type: 0, colorLight: "#00aeef", colorDark: "#00aeef" },
  {
    shortTitle: "Social and emotional \n learning (SEL)",
    code: 21,
    type: 1,
    colorLight: "#00aeef",
    colorDark: "#00aeef",
  },
  { shortTitle: "Difficulties in learning", code: 22, type: 1, colorLight: "#00aeef", colorDark: "#00aeef" },
  { shortTitle: "Intrinsic motivation", code: 23, type: 1, colorLight: "#00aeef", colorDark: "#00aeef" },
  { shortTitle: "Music and SEL", code: 30, type: 0, colorLight: "#d60a0a", colorDark: "#d60a0a" },
  { shortTitle: "Cognitive-behavioral approach", code: 31, type: 1, colorLight: "#d60a0a", colorDark: "#d60a0a" },
  { shortTitle: "Music intervention", code: 32, type: 1, colorLight: "#d60a0a", colorDark: "#d60a0a" },
  { shortTitle: "Digital tools in SEL", code: 33, type: 1, colorLight: "#d60a0a", colorDark: "#d60a0a" },
  { shortTitle: "Case study", code: 40, type: 1, colorLight: "#303030", colorDark: "#ffffff" },
  { shortTitle: "Key elements", code: 50, type: 1, colorLight: "#303030", colorDark: "#ffffff" },
  { shortTitle: "Assessment", code: 60, type: 1, colorLight: "#303030", colorDark: "#ffffff" },
];

export const drawerWidth = 220;
